@mixin form-component-style {
    border-radius: 10px;
    padding: 0.5em;
    font-size: 1em;
}

.chat-container {
    position: fixed;
    top: 24vh;
    right: 1vw;
    width: 25%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid #be9788;
    padding: 2em;
    border-radius: 10px;
    background: #be9788;

    .form-container {
        display: flex;
        justify-content: space-between;

        input {
            width: 92%;
            border-style: none;
            @include form-component-style;

            &:focus {
                outline: none;
            }
        }

        button {
            background: #e5dfdf00;
            padding: 0.5em;
            font-size: 1em;
            width: 2rem;

            svg {
                transition: all 1s;

                &:hover {
                    transition: all 1s;
                    transform: translateX(10px);
                }
            }


            @include form-component-style;

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }
}

.chat {
    position: fixed;
    bottom: 10vh;
    right: 5vw;

    .chat-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
    }

    .icon {
        background-color: #be9788 !important;
        color: #f9f9f9 !important;
        width: unset !important;
        animation: pulse-animation 2s infinite;

        svg {
            font-size: 30px;
        }
    }

    .pulse-animation {
        animation-duration: 2s;
        animation-timing-function: ease;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: none;
        animation-play-state: running;
        animation-name: pulse-animation;
    }

    @keyframes pulse-animation {
        0% {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
        }

        100% {
            box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
        }
    }
}