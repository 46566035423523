.contactUs {
    .sec1Background {
        background-image: url("../../assests/images/contactUs/contactUs.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 150px;

        .sec1 {
            display: grid;
            grid-template-columns: 1fr;
            margin: 0 auto;
            max-width: 1200px;
            padding-right: 4vw;
            padding-left: 4vw;
            box-sizing: content-box;
            padding-top: 4vw;
            padding-bottom: 20vmax;
            text-align: center;

            .sec1-left {
                h1 {
                    font-size: calc(3.5 * 1rem);
                    line-height: calc(1.4 * (1 + (1 - 3.5)/25));
                    font-family: "amiri quran";
                    color: #fff;
                    font-family: orpheus-pro;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: 0em;
                    text-transform: none;
                }
            }
        }
    }
    .sec2Background{
        background-color: #fff;
        margin: 0 auto;
        padding: 5.6vmax 4vw;
        .addressArea{
            .contact-wrapper{
            p{
                margin: unset;
                padding: unset;
            }
            span{
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 10px;
                svg{
                    color:#be9788
                }
            }
            a{
                color: #000;
                text-decoration: none;
            }
            a:hover{
                text-decoration: underline;
            }
        }
        }   
    }

    .sec3Background {
        background-color: #fff;
        margin: 0 auto;
        padding: 0vmax 4vw;
        text-align: center;
        
        iframe{
            width: 100%;
        }
    }
    .sec4Background {
        background-color: #fff;
        margin: 0 auto;
        padding: 2vmax 4vw;
        .form-container{
            padding: 0;
            display: grid;
            grid-template-columns: 50%;
            justify-content: start;
            .send-btn{
                
                width: 100%;
                max-width: 100px;
                border-radius: 50px;
                cursor: pointer;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                border: 1px solid #be9788;
                margin: 15px;
                padding: 10px;
                background: unset;
                color: #be9788;
            }
        }
    }

}
@media (max-width: 600px) {
    .form-container {
        grid-template-columns: 100% !important;
    }
    .radioWrapper{
        grid-template-columns: 50% 50% !important;
    }
}
@media (max-width: 450px) {
    .radioWrapper{
        grid-template-columns: 100% !important;
    }
}
