.header {
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-left: 4vw;
    padding-right: 4vw;
    pointer-events: auto;
    transition: padding 140ms ease-in-out;
    background: none;
    position: fixed;
    top: 0;
    left: 0;
    transition: all .25s ease;

    &.active {
        background: #be9788;
    }

    .header-container {
        display: flex;
        flex-wrap: nowrap;
        flex: 1 0 67%;
        align-items: center;

        .logo-container {
            flex-grow: 0;
            flex-shrink: 0;
            backface-visibility: hidden;

            a {
                text-decoration: none;
                backface-visibility: hidden;
                color: #fff;

                svg {


                    height: 150px;

                    text {
                        fill: #fff;
                    }

                }
            }
        }

        .nav-bar-container {
            text-align: right;
            margin-left: auto;
            padding-left: 2vw;
            flex-grow: 1;
            flex-shrink: 1;

            .nav-links {
                padding-left: 1.2vw;

                a {
                    text-decoration: none;
                    backface-visibility: hidden;
                    color: #fff;
                    font-family: "garment";
                    font-size: calc(1.2 * 1rem);
                }

                a.active {
                    text-decoration: underline;
                }
            }

        }

        .social-media-share {
            display: flex;
            margin: 0;

            .icon {
                margin-left: 1.8vw;
                width: 20px;
                height: 20px;
            }
        }
    }
}

.menuContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuTrigger {
    background: #ffffff;
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 90px;
    cursor: pointer;
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-left: auto;

    svg {
        fill: #d4d4d4 !important;
        font-size: 30px;
    }

    p {
        font-size: 12px;
        margin-bottom: 0px;
        font-family: "Futura_md";
        margin-top: 0px;
    }

    .menuTrigger:hover {
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    }

    span {
        font-weight: 700;
        vertical-align: middle;
        font-size: 14px;
        margin: 0 10px;
    }

    img {
        border-radius: 90px;
    }
}

.popupMenu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 40px;
    right: 0;
    width: 250px;
    padding: 10px 0;
    filter: drop-shadow(0px 1px 8px rgb(0 0 0 / 20%));
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.4s ease;
    z-index: 20;
    pointer-events: none;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 23px;
        z-index: 1;
        background: #FFF;
        width: 15px;
        transform: translateY(-50%) rotate(45deg);
        height: 15px;
    }

    &.active {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
    }



    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: grid;
        align-items: center;
        padding: 6px 20px;
        transition: all 0.25s;
        grid-template-columns: 20% 80%;

        svg {
            color: #be9788 !important;
        }


        a {
            text-decoration: none;
            color: #000;
        }

        &:hover {
            background: #ededed;
        }

        &:last-child {
            display: flex;
            justify-content: space-around;
            align-items: center;



        }

        &.userInfo {
            margin-bottom: 5px;

            &>div {
                padding: 0 15px;

                p {
                    margin: 0;
                    font-size: 12px;
                    line-height: 14px;
                }

                .userName {
                    line-height: 18px;
                    font-weight: 600;
                    font-size: 16px;
                    font-family: "futura_md";
                }
            }
        }
    }

    li h6 {
        text-decoration: none;
        color: #333333;
        // padding: 15px 20px;
        display: block;
        padding: 0 15px !important;
        font-weight: 400 !important;
        width: 100%;
        font-size: 16px !important;
        margin-bottom: 0px !important;
        cursor: pointer;
    }
}

.overlay {
    background-color: transparent;
    z-index: 5;
    position: fixed;
    inset: 0;
}

@media (max-width: 767px) {
    .menuTrigger {
        margin-right: 16px !important;

        p {
            display: none;
        }
    }
}

@media (max-width: 670px) {
    .nav-bar-container {
        display: none !important;
    }
    .social-media-share{
        display: none !important;
    }
    .header-container{
        justify-content: space-between;
    }
    
}
@media (min-width: 671px) {
    .menuContainer {
        display: none !important;
    }
   
}