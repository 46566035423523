.about {

    .sec1Background {
        background-image: url("../../assests/images/contactUs/contactUs.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 150px;

        .sec1 {
            display: grid;
            grid-template-columns: 1fr;
            margin: 0 auto;
            max-width: 1200px;
            padding-right: 4vw;
            padding-left: 4vw;
            box-sizing: content-box;
            padding-top: 4vw;
            padding-bottom: 20vmax;
            text-align: center;

            .sec1-left {
                h1 {
                    font-size: calc(3.5 * 1rem);
                    line-height: calc(1.4 * (1 + (1 - 3.5)/25));
                    font-family: "amiri quran";
                    color: #fff;
                    font-family: orpheus-pro;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: 0em;
                    text-transform: none;
                }
            }
        }
    }

    .sec2Background {
        background-color: #fff;
        margin: 0 auto;
        padding: 0vmax 4vw;

        .heading {
            text-align: left;
            font-size: 25px;
            font-weight: 700;
            margin: 0;
        }

        p {
            margin: 1rem 0;
            white-space: pre-wrap;
            line-height: 2;
            font-size: 1.2rem;
            font-family: orpheus-pro;
        }
    }

    .sec3Background {
        background-color: #fff;
        margin: 0 auto;
        padding: 0vmax 4vw;
        text-align: center;

        .heading {
            text-align: left;
            font-size: 30px;
            font-weight: 500;
            margin: 0;
            text-decoration: underline;
        }

        .description-wrapper {

            p {
                margin: 1rem 0;
                white-space: pre-wrap;
                line-height: 2;
                font-size: 1.2rem;
                font-family: orpheus-pro;
                text-align: left;
            }
        }

        .imageContainer {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
            margin: 0 -5px;
        }
    }
}
.mt50{
    margin-top: 50px !important;
}