.userImageCard {
    display: flex;
    flex-direction: column;

    img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    .name {
        font-weight: 600;
        font-size: 25px;
        margin: 10px auto;
    }

    .designation {
        font-weight: 600;
        margin: 0 auto;
    }
}