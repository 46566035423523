.cardDesign {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
    position: relative;
    overflow: hidden;
    width: calc(50% - 120px);
    margin: 0 60px 60px;
    a {
        text-decoration: none;
        backface-visibility: hidden;
        color: #fff;
    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-transition: all 1s ease-out;
    }
    &:hover{
        img{
            -webkit-transform: scale(1.1);
        }
    }

    .content {
        padding: 48px;
        position: relative;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .4) 100%);
        -webkit-transition: all 1s ease-out;

        .title {
            max-width: 220px;
            line-height: 1.15;
            margin-bottom: 13px;
            letter-spacing: -.4px;
        }

    }
}
@media (max-width: 600px) {
    .content {
       width: 100%;
       padding: 0;
       position: relative;
       background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 100%);
       -webkit-transition: all 1s ease-out;
       flex-direction: column;
       align-items: center;
       left: 0 !important;
    }
}