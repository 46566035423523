.image-viewer {
    .image-container {
        position: relative;
        img {
            width: auto;
            max-width: 100%;
            height: auto;
            display: block;
            padding: 40px 0;
            margin: 0 auto;
        }

        .leftArrow {
            position: absolute;
            top: 50%;
        }

        .rightArrow {
            position: absolute;
            top: 50%;
            right: 0;
        }
    }

    .MuiPaper-root {
        background-color: transparent !important;
        justify-content: center !important;
        padding: 10% !important;
    }
}
@media (max-width: 600px) {
    .closeBtn {
        position: absolute;
        right: -6px;
        top: 10px;

        button {
            background: #fff;
            width: 25px;
            height: 25px;
        }
    }
}