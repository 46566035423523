.servicePage {
    .bg0 {
        background-image: url("../../assests/images/living-area.jpg");
    }

    .bg1 {
        background-image: url("../../assests/images/project-page.jpg");
    }

    .bg2 {
        background-image: url("../../assests/images/home-page-banner.jpg");
    }

    .sec1Background {

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 150px;

        .sec1 {
            display: grid;
            grid-template-columns: 10% 80% 10%;
            margin: 0 auto;
            max-width: 1200px;
            padding-right: 4vw;
            padding-left: 4vw;
            box-sizing: content-box;
            padding-top: 4vw;
            padding-bottom: 20vmax;

            .sec1-left {
                h1 {
                    font-size: calc(3 * 1rem);
                    line-height: calc(1.4 * (1 + (1 - 3.5)/25));
                    color: #fff;
                    font-family: orpheus-pro;
                    font-weight: 400;
                }
            }

            .arrows {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }

    .sec2Background {
        background-color: #fff;
        margin: 0 auto;
        padding: 5.6vmax 4vw;

        .content {
            display: flex;
            flex-wrap: wrap;
        }

    }

}
@media (max-width: 670px) {
    .servicePage{
    .sec1Background{
        .sec1{
            grid-template-rows: 10% 80% 10% !important;
            grid-template-columns: unset !important
        }
        .arrows{
            justify-content: center !important;
        }
    }
}
}