.projectPage {
    .sec1Background {
        background-image: url("../../assests/images/project-page.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 150px;

        .sec1 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 0 auto;
            max-width: 1200px;
            padding-right: 4vw;
            padding-left: 4vw;
            box-sizing: content-box;
            padding-top: 4vw;
            padding-bottom: 20vmax;

            .sec1-left {
                h1 {
                    font-size: calc(3.5 * 1rem);
                    line-height: calc(1.4 * (1 + (1 - 3.5)/25));
                    font-family: "amiri quran";
                    color: #fff;
                    font-family: orpheus-pro;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: 0em;
                    text-transform: none;
                }
            }
        }
    }

    .sec2Background {
        background-color: #fff;
        padding: 50px;

        .content {
            display: flex;
            flex-wrap: wrap;
        }

    }

}

@media (max-width: 1050px) {
    .sec2Background{
        padding: 50px 20px !important;
    }
    .content {
        display: flex;
        flex-direction: column;
        background: unset !important;
        align-items: center;
        margin: 0 !important;
        

        img {
            width: 100% !important;
        }
    }
    .cardDesign{
        margin: 0 0 25px 0 !important;
        width: 100% !important;
        gap: 20px;
    }
}