.homePage {
    .sec1Background {
        background-image: url("../../assests/images/home-page-banner.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 150px;

        .sec1 {
            display: grid;
            grid-template-columns: 3fr 1fr;
            margin: 0 auto;
            max-width: 1200px;
            padding-right: 4vw;
            padding-left: 4vw;
            box-sizing: content-box;
            padding-top: 4vw;
            padding-bottom: 20vmax;

            .sec1-left {
                h1 {
                    font-size: calc(3.5 * 1rem);
                    line-height: calc(1.4 * (1 + (1 - 3.5)/25));
                    color: #fff;
                    font-family: orpheus-pro;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: 0em;
                    text-transform: none;
                    margin-bottom: unset;
                }
                .wrapper{
                    display: flex;
                        font-size: calc(3.5 * 1rem);
                        line-height: calc(1.4 * (1 + (1 - 3.5)/25));
                        font-family: "amiri quran";
                        color: #fff;
                        font-family: orpheus-pro;
                        font-weight: 400;
                        font-style: normal;
                        letter-spacing: 0em;
                        text-transform: none;
                        margin-bottom: unset;
                    .words{
                        overflow: hidden;
                        height: 70px;
                        span{
                            display: block;
                            height: 100%;
                            padding-left: 10px;    
                            color: #fff;                     
                            animation: spin_words 6s infinite;
                        }
                    }
                }
            }
        }
    }

    .sec2Background {
        background-color: #d5d2cd;

        .sec2 {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            box-sizing: content-box;
            width: 100%;
            position: relative;
            max-width: 1200px;
            padding: 5.6vmax 0vw;

            .content {
                width: 75%;
                padding-left: 17px;
                padding-right: 17px;

                h3 {
                    text-align: center;
                    white-space: pre-wrap;
                    font-weight: 400;
                    font-size: calc(2.1 * 1rem);
                    line-height: calc(1.4 * (1 + (1 - 2.1)/25));
                    margin: 2rem 0;
                    letter-spacing: 0em;
                    text-transform: none;
                    font-style: normal;
                }
                .author{
                    margin: 20px 0;
                    font-size: 1.8rem;
                }

                p {
                    margin: 1rem 0;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                    -webkit-font-smoothing: antialiased;
                    line-height: 1.8;
                    letter-spacing: 0em;
                    font-style: normal;
                    font-size: 1.09rem;
                }
            }

        }
    }

    .sec3Background {
        background-image: url("../../assests/images/sec-3-banner.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .sec3 {
            margin: 0 auto;
            box-sizing: content-box;
            padding-top: 10vmax;
            padding-bottom: 20vmax;
            width: 100%;
            position: relative;
            max-width: 1200px;
            display: flex;
            justify-content: center;
            position: relative;
            max-width: 1200px;
            font-size: calc(1 * 1rem);
            font-family: Open Sans;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0em;
            text-transform: none;
            line-height: 1.8;
            -webkit-font-smoothing: antialiased;
        }
    }

    .sec4Background {
        background-color: #be9788;
        align-items: center;
        min-height: 66vh;
        position: relative;
        display: flex;
        box-sizing: border-box;

        .sec4 {
            padding-top: 6.6vmax;
            padding-bottom: 6.6vmax;
            justify-content: center;
            padding-right: 4vw;
            padding-left: 4vw;
            margin: 0 auto;
            box-sizing: content-box;
            display: flex;
            max-width: 1200px;

            .content {
                display: flex;

                .left-Content {
                    width: 50%;

                    img {
                        width: 50%;
                    }
                }

                .right-content {
                    width: 50%;

                    li {
                        margin: 1rem 0;
                    }
                }
            }
        }
    }

    .sec5Background {
        background-color: #d5d2cd;
        align-items: center;
        min-height: 66vh;
        position: relative;
        display: flex;
        box-sizing: border-box;

        .sec5 {
            padding-top: 6.6vmax;
            padding-bottom: 6.6vmax;
            justify-content: center;
            padding-right: 4vw;
            padding-left: 4vw;
            margin: 0 auto;
            box-sizing: content-box;
            display: flex;
            max-width: 1200px;

            .content {
                display: flex;

                .left-Content {
                    width: 50%;

                    img {
                        width: 50%;
                    }
                }

                .right-content {
                    width: 50%;

                    li {
                        margin: 1rem 0;
                    }
                }
            }
        }
    }


    .sec6Background {
        background-image: url("../../assests/images/contact-us-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .sec6 {
            margin: 0 auto;
            padding-top: 10vmax;
            padding-bottom: 20vmax;
            width: 100%;
            max-width: 1200px;
            font-size: calc(1 * 1rem);
            font-family: Open Sans;
            line-height: 1.8;

            .sec6-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                h1 {
                    color: #fff;
                    text-align: center;
                    white-space: pre-wrap;
                    font-size: calc(2.8 * 1rem);
                }

                button {
                    padding: 20px 25px;
                    width: 100%;
                    max-width: 150px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }

        }
    }

    .sec7Background {
        background-color: #d5d2cd;
        background-position: center;
        background-size: cover;

        .sec7 {
            padding-bottom: 5vmax;
        }
    }

}

@media (max-width: 1050px) {
    .content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img{
        width: 100% !important;
    }
    }
}

@media (max-width: 640px) {
    .content{
   .left-Content{
    width: 100% !important;
   }
   .right-content{
    width: 100% !important;
   }
    img{
        width: 100% !important;
    }
    }
}

@keyframes spin_words {
    0%{
        transform: translateY(-200%);
    }
    10%{
        transform: translateY(-112%);
    }
    25%{
        transform: translateY(-100%);
    }
    35%{
        transform: translateY(-212%);
    }
    50%{
        transform: translateY(-200%);
    }
    60%{
        transform: translateY(-312%);
    }
    75%{
        transform: translateY(-300%);
    }
    85%{
        transform: translateY(-412%);
    }
    100%{
        transform: translateY(-400%);
    }
}
@media (max-width: 1083px) {
    .sec1{
        display: block !important;
    }
}
@media (max-width: 813px) {
    .static_text{
        margin: unset !important;
    }
    .words{
        span{
            display: flex !important;
            justify-content: center !important;
        }
    }
}

@media (max-width: 557px) {
.wrapper{
    font-size: 2.5rem !important;
    padding: 20px !important;
    flex-direction: column !important;
}
.sec1{
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 15vmax !important;
}
.slidingVertical{
    display: flex !important;
    text-indent: 8px !important;
    justify-content: center !important;
    span{
        font-size: 2.5rem !important;
    }

}
.static_text{
    font-weight: 400;
    font-size: 2.5rem !important;
    text-align: center !important;
}
}

.sentence{
    color: #222;
    font-size: 30px;
    text-align: left;
}
.slidingVertical{
   display: inline;
   text-indent: 8px;
}
.slidingVertical span{
   animation: topToBottom 12.5s linear infinite 0s;
   color: #fff;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   font-size: 3.5rem;
   text-align: center;
}

.slidingVertical span:nth-child(2){
   animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3){
   animation-delay: 5s;
}
.slidingVertical span:nth-child(4){
   animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5){
   animation-delay: 10s;
}	

@keyframes topToBottom{
   0% { opacity: 0; }
   5% { opacity: 0; transform: translateY(-50px); }
   10% { opacity: 1; transform: translateY(0px); }
   25% { opacity: 1; transform: translateY(0px); }
   30% { opacity: 0; transform: translateY(50px); }
   80% { opacity: 0; }
   100% { opacity: 0; }
}
.static_text{
    font-weight: 400;
    font-size: 3.5rem;
    margin: unset;
}