button {
    padding: 20px 25px;
    width: 100%;
    max-width: 150px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}