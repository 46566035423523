.projectDetailsPage {
    .sec1Background {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 150px;        

        .sec1 {
            display: grid;
            grid-template-columns: 1fr;
            margin: 0 auto;
            max-width: 1200px;
            padding-right: 4vw;
            padding-left: 4vw;
            padding-top: 4vw;
            padding-bottom: 20vmax;

            .sec1-left {
                background-color: rgb(204 207 210 / 61%);

                h1,
                h6 {
                    font-size: calc(3.5 * 1rem);
                    line-height: calc(1.4 * (1 + (1 - 3.5)/25));
                    color: #fff;
                    font-family: orpheus-pro;
                    font-weight: 400;
                    letter-spacing: 0em;
                    text-align: center;
                }

                h6 {
                    font-size: calc(1.5 * 1rem);
                }
            }
        }
    }

    .sec2Background {
        .heading {
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            padding: 30px 0px;
        }

        .overview-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            padding: 10px 50px;

            .element {
                text-align: center;

                svg {
                    font-size: calc(3.5 * 1rem);
                    color: #be9788;
                }
                h1{
                    color: #333;
                    font-weight: 500;
                }
                h6{
                    color: #666;
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
        .description-wrapper{
            padding: 10px 50px;
            p{
                margin: 1rem 0;
                white-space: pre-wrap;
                line-height: 2;
                font-size: 1.2rem;
                font-family: orpheus-pro;
            }
        }
    }

    .sec3Background {
        background-color: #fff;
        margin: 0 auto;
        padding: 2vmax 4vw;

        .imageContainer {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
            margin: 0 -5px;
            gap: 10vh;

            .item {
                cursor: pointer;
                display: flex;
                min-height: 250px;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;
                -webkit-box-pack: end;
                justify-content: flex-end;
                color: #fff;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    -webkit-transition: all 1s ease-out;
                    max-width: 100%;
                    flex-shrink: 0;
                }

                &:hover {
                    img {
                        -webkit-transform: scale(1.1);
                    }
                }
            }
        }

    }
    .sec4Background{
        padding: 2vmax 4vw;
        .heading {
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            padding: 0px;
            margin: 0;
        }
        .sec4{
            display: grid;
            grid-template-columns: 100%;
            align-items: center;
            .profilePics{
               img{
                width: 100%;
                border-radius: 50px;
                padding: 50px;
               }
            }
            .reviewArea{
                p{
                    margin: 1rem 0;
                    white-space: pre-wrap;
                    line-height: 2;
                    font-size: 1.2rem;
                    font-family: orpheus-pro;
                    text-align: center;
                }
                h1{
                    text-align: right;
                    padding: 0px 50px;
                    color: #be9788;
                }
            }
        }
    }

}
@media only screen and (min-width:568px) and (max-width:991px){
    .overview-wrapper{
        grid-template-columns: 1fr 1fr !important;
    }
    .projectDetailsPage .sec2Background .description-wrapper{
        padding: 10px 0px !important;
    }
}
@media (max-width: 567px) {
    .overview-wrapper{
        grid-template-columns: 1fr !important;
    }
    .projectDetailsPage .sec2Background .description-wrapper{
        padding: 10px 0px !important;
    }
}