body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "garmont";
  src: url("/src/assests/font/EB_Garamond/EBGaramond-VariableFont_wght.ttf");
}

@font-face {
  font-family: "amiri quran";
  src: url("/src/assests/font/Amiri_Quran/EB_Garamond/EBGaramond-VariableFont_wght.ttf");
}
#root{
  position: relative;
}