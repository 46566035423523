.footer {
    // background-image: url("../../assests/images/footer-background.svg");
    background-size: 300px 300px;
    background-repeat: repeat;
    background-position: left top;
    background-color: #d5d2cd;

    .footer-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 25px;

        section {
            text-align: center;
            padding: 10px;
        }

        .logo-section {
            img {
                width: auto;
                max-width: 100%;
                max-height: 100px;
                border: 0;
            }

            p {
                text-align: left;
                font-weight: 600;
                margin: 1rem 0;
                padding-left: 24px;
            }
        }

        .navigation-section {
            .links-wrapper {
                display: flex;
                flex-direction: column;

                a {
                    margin: 0.5rem 0;
                    text-decoration: none;
                    backface-visibility: hidden;
                    color: #83736c;
                    font-family: "garment";
                    font-size: 1.2rem;
                }

                a:hover {
                    padding-left: 10px;
                    text-decoration: underline;
                    transition: 0.5s ease;
                }
            }
        }

        .contact-section {
            .contact-wrapper {
                p {
                    margin: 5px 0px;
                }

                span {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    justify-content: center;
                    margin-top: 10px;
                }

                a {
                    text-decoration: none;
                    backface-visibility: hidden;
                    color: #000;
                }
            }
        }
    }
}
@media (max-width: 500px) {
    .footer-wrapper {
        grid-template-columns: 1fr !important;
    }
}